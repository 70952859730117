//@ts-nocheck
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import TagFacesIcon from "@material-ui/icons/TagFaces";

interface ChipData {
  key: number;
  label: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      padding: theme.spacing(0.5),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  })
);

export default function ChipsArray({ options, setOptions }) {
  const classes = useStyles();
  const [chipData, setChipData] = React.useState<ChipData[]>(
    options.map((str, i) => ({ key: i, label: str }))
  );

  const handleDelete = (chipToDelete: ChipData) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
    const newOptions = [...options];
    newOptions.splice(chipToDelete.key, 1);
    setOptions(newOptions);
  };

  React.useEffect(() => {
    setChipData(options.map((str, i) => ({ key: i, label: str })));
  }, [options]);

  return (
    <Paper className={classes.root}>
      {chipData.map((data) => {
        let icon;

        //if (data.label === 'React') {
        //  icon = <TagFacesIcon />;
        //}

        return (
          <Chip
            key={data.key}
            icon={icon}
            label={data.label}
            onDelete={data.label === "React" ? undefined : handleDelete(data)}
            className={classes.chip}
          />
        );
      })}
    </Paper>
  );
}
