import { DigimonData } from "./cardinfo";

const attr = [
  "",
  "Data",
  "Vaccine",
  "Virus",
  "Free",
  "Variable",
  "Unknown",
  "",
];

const cardtype = ["", "Digitama", "Digimon", "Tamer", "Option"];

export const color = [
  "",
  "Red",
  "",
  "Blue",
  "Yellow",
  "Black",
  "Green",
  "Purple",
  "White",
  "",
];

const stage = [
  "Baby",
  "In Training",
  "Rookie",
  "Champion",
  "Ultimate",
  "Mega",
  "Unknown",
  "",
];

export const rare = [
  "Common",
  "Uncommon",
  "Rare",
  "Super Rare",
  "Promo",
  "Secret Rare",
];

const keepNull = (val: any, finalVal: any) => {
  return val === undefined || val === null ? null : finalVal;
};

export const autokeywords = [...attr, ...color, ...rare, ...stage, ...cardtype];
export function dataMapper(d: {
  [property: string]: string | null;
}): DigimonData {
  const temp = {
    id: d.cardid,
    name: d.name,
    ctype: cardtype[Number(d.cardtype)],
    color: keepNull(d.color, color[Number(d.color)]),
    ecostcolor: keepNull(d.ecostcolor, color[Number(d.ecostcolor)]),
    ecostcolor2: keepNull(d.ecostcolor2, color[Number(d.ecostcolor2)]),
    cost: keepNull(d.cost, Number(d.cost)),
    ecost: keepNull(d.ecost, Number(d.ecost)),
    ecost2: keepNull(d.ecost2, Number(d.ecost2)),
    lv: keepNull(d.level, Number(d.level)),
    dp: keepNull(d.dp, Number(d.dp)),
    dtype: d.dtype,
    attr: keepNull(d.attr, attr[Number(d.attr)]),
    stage: keepNull(d.stage, stage[Number(d.stage)]),
    rare: rare[Number(d.rare)],
    seffect: d.sourceeffect,
    meffect: d.maineffect,
    seceffect: d.securityeffect,
    notes: d.notes,
    imageUrl: d.imageUrl,
    language: Number(d.language),
    gid: Number(d.gid),
    artist: d.artist,
    src: d.src,
    intl: d.intl === "1",
  };
  // @ts-ignore
  temp.json = JSON.stringify(temp).toLocaleLowerCase();
  // @ts-ignore
  return temp;
}
