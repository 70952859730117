import React, { useState, useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Avatar from "@material-ui/core/Avatar";
import cardsStore from "../store/cards/cards";
import { UserContext } from "../connections/userProvider";
import { findCardByCardId } from "./eventDetail";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const AvatarHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      border: "1px solid lightgrey",
      padding: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
    },
    decklist: {
      border: "1px solid lightgrey",
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    decklistContainer: {
      background: ({ isDragging }) => (isDragging ? "grey" : "inherit"),
    },
    decklists: {
      flexGrow: "1",
      minHeight: "50px",
    },
    large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      border: "10px solid white",
      margin: theme.spacing(1),
      "& img": {
        width: "150%",
        height: "unset",
        marginTop: "15%",
      },
    },
  })
);

const initialdata = {
  decklists: [1, 2, 3, 4].reduce((acc, cur) => {
    acc[cur] = { id: "" + cur, content: "d" + cur };
    return acc;
  }, {}),
  columns: {
    c1: { id: "c1", title: "Local", decklistsId: ["2", "3", "4"] },
    c2: { id: "c2", title: "Cloud", decklistsId: ["1"] },
  },
  columnsOrder: ["c1", "c2"],
};

function DecklistContainer(props) {
  const { children, isDragging, ref, ...otherProps } = props;
  const classes = useStyles({ isDragging });
  return (
    <div ref={ref} className={classes.decklistContainer} {...otherProps}>
      {children}
    </div>
  );
}

function Decklist({ decklist, index }) {
  const classes = useStyles();
  return (
    <Draggable draggableId={decklist.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={classes.decklist}
          isDragging={snapshot.isDragging}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {decklist.content}
        </div>
      )}
    </Draggable>
  );
}

function Column({ column, decklists }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h3>{column.title}</h3>
      <Droppable droppableId={column.id}>
        {(provided) => (
          <div
            className={classes.decklists}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {decklists.map((decklist, index) => (
              <Decklist key={decklist.id} decklist={decklist} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

export default function MyDecks() {
  const classes = useStyles();
  const [state, setState] = useState(initialdata);
  const cards = cardsStore.useCards();
  const { user, signOut, signedIn } = useContext(UserContext);
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const sourceColumn = state.columns[source.droppableId];

    const sourceDecklistsIds = Array.from(sourceColumn.decklistsId);
    sourceDecklistsIds.splice(source.index, 1);

    const newSourceColumn = {
      ...sourceColumn,
      decklistsId: sourceDecklistsIds,
    };

    const destColumn =
      destination.droppableId === source.droppableId
        ? newSourceColumn
        : state.columns[destination.droppableId];
    const destDecklistIds = Array.from(destColumn.decklistsId);
    destDecklistIds.splice(destination.index, 0, draggableId);
    const newDestColumn = {
      ...destColumn,
      decklistsId: destDecklistIds,
    };

    setState({
      ...state,
      columns: {
        ...state.columns,
        [newSourceColumn.id]: newSourceColumn,
        [newDestColumn.id]: newDestColumn,
      },
    });
  };

  const mapped = state.columnsOrder.map((colId) => {
    const column = state.columns[colId];
    const decklists = column.decklistsId.map(
      (decklistId) => state.decklists[decklistId]
    );

    return <Column key={column.id} column={column} decklists={decklists} />;
  });

  let card = undefined;
  if (signedIn) {
    console.log(cards);
    card =
      findCardByCardId(user.doc.favCard, cards.basecards) ||
      findCardByCardId("BT1-001", cards.basecards) ||
      null;
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        {signedIn && card && (
          <>
            <AvatarHeader>
              <Avatar
                src={`${card.imageUrl}`}
                className={classes.large}
                style={{ borderColor: card.color }}
              ></Avatar>
              <Typography variant="h3">{user.doc.displayName}</Typography>
            </AvatarHeader>
            <ButtonGroup variant="contained" color="primary">
              <Button onClick={signOut}>Sign Out</Button>
              <Button>Two</Button>
              <Button>Three</Button>
            </ButtonGroup>
          </>
        )}
        {mapped}
      </DragDropContext>
    </>
  );
}
