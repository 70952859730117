//@ts-nocheck
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import BuildIcon from "@material-ui/icons/Build";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ChipsArray from "./chipsArray";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
//import MultipleSelect from "./language";

import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import blue from "@material-ui/core/colors/blue";

const lightTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: blue,
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageRoot: {
      height: "100%",
      width: "100vw",
      padding: "1vmin",
    },
    group: {
      margin: "0.25vmin",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
  })
);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const sets = [
  `"id":"ST1`,
  `"id":"ST2`,
  `"id":"ST3`,
  `"id":"ST4`,
  `"id":"ST5`,
  `"id":"ST6`,
  `"id":"BT1`,
  `"id":"BT2`,
  `"id":"BT3`,
  `"id":"BT4`,
];

const setNames = [
  "ST1 Gaia Red",
  "ST2 Cocytus Blue",
  "ST3 Heaven's Yellow",
  "ST4 Giga Green",
  "ST5 Mugen Black",
  "ST6 Venom Purple",
  "BT1 New Evolution",
  "BT2 Ultimate Power",
  "BT3 Union Impact",
  "BT4 Great Legend",
];

export default function FilterTool(props) {
  const { setFilter, setSort, alt, setAlt } = props;
  const filter: string[] = props.filter;
  const sort: (string | boolean)[] = props.sort;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [tempFilter, setTempFilter] = useState([]);
  const [tempSort, setTempSort] = useState(["id", "id", true, "auto"]);
  const [tempAlt, setTempAlt] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    setTempFilter(filter);
    setTempSort(sort);
    setTempAlt(alt);
    //setSetTemp(filter.filter((val) => val.slice(0, 5).localeCompare(`"id":`)));
  }, [filter, sort, alt]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAndSave = () => {
    setFilter(tempFilter);
    setSort(tempSort);
    setAlt(tempAlt);
    setOpen(false);
  };

  const addFilter = (f: string) => {
    return () => {
      if (tempFilter.includes(f)) {
        return;
      }
      setTempFilter([...tempFilter, f]);
    };
  };

  const toggleFilter = (f: string) => {
    return () => {
      if (tempFilter.includes(f)) {
        setTempFilter(tempFilter.filter((val) => val != f));
      } else {
        setTempFilter([...tempFilter, f]);
      }
    };
  };

  const handleSort = (index: number, val: string) => {
    const newsort = [...tempSort];

    if (index == 2) {
      newsort[index] = val.localeCompare("true") == 0;
    } else {
      newsort[index] = val;
    }
    setTempSort(newsort);
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    //setAlt( event.target.checked );
    setTempAlt(event.target.checked);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <BuildIcon />
      </Button>
      <ThemeProvider theme={lightTheme}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Filter Tools</DialogTitle>
          <ChipsArray options={tempFilter} setOptions={setTempFilter} />
          <DialogContent>
            <DialogTitle id="form-dialog-title">Filter Options</DialogTitle>
            <DialogContentText>
              Click below to add filters to your search.
            </DialogContentText>
            <div className={classes.group}>
              <ButtonGroup
                variant="contained"
                color="primary"
                aria-label="contained primary button group"
              >
                <Button onClick={addFilter(`"color":"red"`)}>Red</Button>
                <Button onClick={addFilter(`"color":"blue"`)}>Blue</Button>
                <Button onClick={addFilter(`"color":"yellow"`)}>Yellow</Button>
                <Button onClick={addFilter(`"color":"green"`)}>Green</Button>
                <Button onClick={addFilter(`"color":"purple"`)}>Purple</Button>
                <Button onClick={addFilter(`"color":"black"`)}>Black</Button>
                <Button onClick={addFilter(`"color":"white"`)}>White</Button>
              </ButtonGroup>
            </div>
            <ButtonGroup
              className={classes.group}
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              <Button onClick={addFilter(`"ctype":"digitama"`)}>
                Digitama
              </Button>
              <Button onClick={addFilter(`"ctype":"digimon"`)}>Digimon</Button>
              <Button onClick={addFilter(`"ctype":"tamer"`)}>Tamer</Button>
              <Button onClick={addFilter(`"ctype":"option"`)}>Option</Button>
            </ButtonGroup>
            <ButtonGroup
              className={classes.group}
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              <Button onClick={addFilter(`"lv":2`)}>Lv 2</Button>
              <Button onClick={addFilter(`"lv":3`)}>3</Button>
              <Button onClick={addFilter(`"lv":4`)}>4</Button>
              <Button onClick={addFilter(`"lv":5`)}>5</Button>
              <Button onClick={addFilter(`"lv":6`)}>6</Button>
              <Button onClick={addFilter(`"lv":7`)}>7</Button>
            </ButtonGroup>
            <ButtonGroup
              className={classes.group}
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              <Button onClick={addFilter(`"rare":"Common"`)}>C</Button>
              <Button onClick={addFilter(`"rare":"Uncommon"`)}>U</Button>
              <Button onClick={addFilter(`"rare":"Rare"`)}>R</Button>
              <Button onClick={addFilter(`"rare":"Super`)}>SR</Button>
              <Button onClick={addFilter(`"rare":"Promo`)}>P</Button>
              <Button onClick={addFilter(`"rare":"Sec`)}>SEC</Button>
            </ButtonGroup>
            <ButtonGroup
              className={classes.group}
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              <Button onClick={addFilter(`multicolor`)}>Multicolor</Button>
              <Button onClick={addFilter(`parallel`)}>Parallel</Button>
            </ButtonGroup>
            <ButtonGroup
              className={classes.group}
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              <Button onClick={addFilter(`@cost@eq@0`)}>Cost 0</Button>
              <Button onClick={addFilter(`@cost@eq@1`)}>1</Button>
              <Button onClick={addFilter(`@cost@eq@2`)}>2</Button>
              <Button onClick={addFilter(`@cost@eq@3`)}>3</Button>
              <Button onClick={addFilter(`@cost@eq@4`)}>4</Button>
              <Button onClick={addFilter(`@cost@eq@5`)}>5</Button>
              <Button onClick={addFilter(`@cost@eq@6`)}>6</Button>
              <Button onClick={addFilter(`@cost@eq@7`)}>7</Button>
              <Button onClick={addFilter(`@cost@eq@8`)}>8</Button>
              <Button onClick={addFilter(`@cost@eq@9`)}>9</Button>
              <Button onClick={addFilter(`@cost@eq@10`)}>10</Button>
              <Button onClick={addFilter(`@cost@gte@11`)}>{"\u2265"}11</Button>
            </ButtonGroup>
            <Button
              onClick={handleMenuClick}
              variant="contained"
              color="primary"
            >
              Filter by Set <ExpandMoreIcon />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "30ch",
                },
              }}
            >
              {sets.map((name, index) => (
                <MenuItem
                  key={name}
                  value={setNames[index]}
                  onClick={toggleFilter(name)}
                >
                  <Checkbox checked={tempFilter.includes(name)} />
                  <ListItemText primary={setNames[index]} />
                </MenuItem>
              ))}
            </Menu>
            {/* <ButtonGroup
            className={classes.group}
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
          >
            <Button onClick={addFilter(`"id":"ST1`)}>Gaia Red</Button>
            <Button onClick={addFilter(`"id":"ST2`)}>Cocytus Blue</Button>
            <Button onClick={addFilter(`"id":"ST3`)}>Heaven's Yellow</Button>
            <Button onClick={addFilter(`"id":"BT1`)}>New Evolution</Button>
            <Button onClick={addFilter(`"id":"BT2`)}>Ultimate Power</Button>
            <Button onClick={addFilter(`"id":"BT3`)}>Union Impact</Button>
          </ButtonGroup>
          */}
            <ButtonGroup
              className={classes.group}
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              <Button onClick={addFilter(`@dp@gte@2000`)}>
                DP {"\u2265"} 2K
              </Button>
              <Button onClick={addFilter(`@dp@gte@7000`)}>
                DP {"\u2265"} 7k
              </Button>
              <Button onClick={addFilter(`@dp@gte@10000`)}>
                DP {"\u2265"} 10k
              </Button>
            </ButtonGroup>
            <ButtonGroup
              className={classes.group}
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              <Button onClick={addFilter(`@dp@lte@2000`)}>
                DP {"\u2264"} 2K
              </Button>
              <Button onClick={addFilter(`@dp@lte@7000`)}>
                DP {"\u2264"} 7k
              </Button>
              <Button onClick={addFilter(`@dp@lte@10000`)}>
                DP {"\u2264"} 10k
              </Button>
            </ButtonGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tempAlt}
                  onChange={handleCheck}
                  name="checked"
                  color="primary"
                />
              }
              label="Include Parallel/Alternate Art"
            />
            {/*
          <DialogTitle id="form-dialog-title">Language Sets</DialogTitle>
          <MultipleSelect />
            */}
            <DialogTitle id="form-dialog-title">Sort Results</DialogTitle>
            <FormControl component="fieldset">
              <FormLabel component="legend">Sort first by</FormLabel>
              <RadioGroup
                row={true}
                aria-label="sortby"
                name="sortby"
                value={tempSort[0]}
                onChange={(e, v) => {
                  handleSort(0, v);
                }}
              >
                <FormControlLabel
                  value="id"
                  control={<Radio />}
                  label="Card ID"
                />
                <FormControlLabel
                  value="cost"
                  control={<Radio />}
                  label="Cost"
                />
                <FormControlLabel
                  value="ecost"
                  control={<Radio />}
                  label="Evo Cost"
                />
                <FormControlLabel value="dp" control={<Radio />} label="DP" />
                <FormControlLabel
                  value="lv"
                  control={<Radio />}
                  label="Level"
                />
                <FormControlLabel
                  value="color"
                  control={<Radio />}
                  label="Color"
                />
                <FormControlLabel
                  value="artist"
                  control={<Radio />}
                  label="Artist"
                />
              </RadioGroup>
              <FormLabel component="legend">Then sort by</FormLabel>
              <RadioGroup
                disabled
                row={true}
                aria-label="sortby2"
                name="sortby2"
                value={tempSort[1]}
                onChange={(e, v) => {
                  handleSort(1, v);
                }}
              >
                <FormControlLabel
                  disabled
                  value="id"
                  control={<Radio />}
                  label="Card ID"
                />
                <FormControlLabel
                  disabled
                  value="cost"
                  control={<Radio />}
                  label="Cost"
                />
                <FormControlLabel
                  disabled
                  value="ecost"
                  control={<Radio />}
                  label="Evo Cost"
                />
                <FormControlLabel
                  disabled
                  value="dp"
                  control={<Radio />}
                  label="DP"
                />
                <FormControlLabel
                  disabled
                  value="lv"
                  control={<Radio />}
                  label="Level"
                />
                <FormControlLabel
                  disabled
                  value="color"
                  control={<Radio />}
                  label="Color"
                />
                <FormControlLabel
                  disabled
                  value="artist"
                  control={<Radio />}
                  label="Artist"
                />
              </RadioGroup>
              <FormLabel component="legend">Order</FormLabel>
              <RadioGroup
                row={true}
                aria-label="order"
                name="order"
                value={tempSort[2] ? "true" : "false"}
                onChange={(e, v) => {
                  handleSort(2, v);
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Ascending"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Descending"
                />
              </RadioGroup>
              <FormLabel component="legend">Search Type</FormLabel>
              <RadioGroup
                row={true}
                aria-label="searchtype"
                name="searchtype"
                value={tempSort[3]}
                onChange={(e, v) => {
                  handleSort(3, v);
                }}
              >
                <FormControlLabel
                  value="auto"
                  control={<Radio />}
                  label="AUTO"
                />
                <FormControlLabel value="and" control={<Radio />} label="AND" />
                <FormControlLabel value="or" control={<Radio />} label="OR" />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCloseAndSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
