import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import cardsStore from "./store/cards/cards";
import Main from "./components/dashboard";
import UserProvider from "./connections/userProvider";
import { useCookies } from "react-cookie";
const App = () => {
  //const [state, setState] = useState(cardsStore.initialState);
  const [dashOpen, setDashOpen] = useState(window.innerWidth > 900);
  const [cookies, setCookie] = useCookies(["language"]);

  useEffect(() => {
    //cardsStore.subscribe(setState);
    cardsStore.init();
    cardsStore.load();
  }, []);

  useEffect(() => {
    if (cookies.language == undefined || cookies.language == null) {
      setCookie("language", JSON.stringify([231]));
    }
    if (cookies.formats == undefined || cookies.formats == null) {
      setCookie("formats", JSON.stringify(["official"]));
    }

    if (cookies.language || cookies.formats) {
      var newState = {};
      if (cookies.language) {
        newState.language = cookies.language;
      }
      if (cookies.formats) {
        newState.formats = cookies.formats;
      }
      cardsStore.setState(newState);
    }
  }, [cookies]);

  return (
    <>
      <BrowserRouter>
        <>
          <UserProvider>
            <Main open={dashOpen} setOpen={setDashOpen} />
          </UserProvider>
          {/*
      <Switch>
        <Route path="/"   exact  render={
          (props)=><Dashboard open={dashOpen} setOpen={setDashOpen}/>
        }/>
      </Switch>
      */}
        </>
      </BrowserRouter>
    </>
  );
};

export default App;
