import React, { useEffect } from "react";
//import clsx from 'clsx';
import { useTheme, createStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { VariableSizeList } from "react-window";

import Autocomplete from "@material-ui/lab/Autocomplete";
import cardsStore from "../store/cards/cards";
import { FilterFunction } from "../filters";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import ImageContainer from "./image-container";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import CardsToggle from "./cardsToggle";
import { useCookies } from "react-cookie";
import FilterTool from "./filterTool";

const autocompleteOptions = [""];
const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const useStyles = makeStyles((theme) =>
  createStyles({
    search: {
      width: "100%",
      paddingTop: "0.5vmin",
    },
    page: {
      marginTop: "-32px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    header: {
      flexGrow: 0,
    },
    scroller: {
      overflow: "hidden",
      flexGrow: 1,
      position: "relative",
    },
    innerScroller: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflowY: "scroll",
      display: "flex",
      flexWrap: "wrap",
      alignContent: "start",
    },
    zoomcontrols: {
      display: "flex",
      alignItems: "center",
      paddingTop: "0.5vmin",
    },
    wrapper: {
      display: "flex",
    },
  })
);

var Library = (props) => {
  const classes = useStyles();
  const {
    filteredcards,
    basecards,
    sort,
    language,
    alt,
    formats,
    filter,
    size,
  } = cardsStore.useCards();
  const {
    setFilteredcards,
    setFilter,
    setSize,
    setFormats,
    setSort,
    setAlt,
  } = cardsStore;
  const [cookies, setCookie] = useCookies(["language"]);

  useEffect(() => {
    FilterFunction(
      basecards,
      filter,
      sort,
      setFilteredcards,
      language,
      alt,
      formats
    );
  }, [filter, sort, basecards, language, alt, formats]);

  useEffect(() => {
    basecards.forEach((card) => {
      autocompleteOptions.push(card.name);
    });
  }, [basecards]);

  const zoomin = () => {
    const newValue = Math.min(50, size + 5);
    setSize(newValue);
  };
  const zoomout = () => {
    const newValue = Math.max(5, size - 5);
    setSize(newValue);
  };

  const handleChange = (event, newValue) => {
    setSize(newValue);
  };

  const setFormats2 = (fmt) => {
    setCookie("formats", JSON.stringify(fmt));
    setFormats(fmt);
  };

  return (
    <>
      <div className={classes.page}>
        <div className={classes.header}>
          <div className={classes.zoomcontrols}>
            <CardsToggle formats={formats} setFormats={setFormats2} />
            <Button onClick={zoomout}>
              <ZoomOutIcon />
            </Button>

            <Slider
              step={5}
              min={5}
              max={60}
              value={size}
              onChange={handleChange}
              aria-labelledby="continuous-slider"
            />

            <Button onClick={zoomin}>
              <ZoomInIcon />
            </Button>
            <FilterTool
              sort={sort}
              setSort={setSort}
              filter={filter}
              setFilter={setFilter}
              alt={alt}
              setAlt={setAlt}
            />
          </div>
          <Autocomplete
            className={classes.search}
            multiple={true}
            disableListWrap
            id="tags-filled"
            options={autocompleteOptions}
            freeSolo
            ListboxComponent={ListboxComponent}
            onChange={(event, value, reason) => {
              setFilter(value);
            }}
            value={filter}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Search here or click the wrench for filters"
                placeholder="Try 'Agumon'"
              />
            )}
          />
        </div>
        <div className={classes.scroller}>
          <div className={classes.innerScroller}>
            {filteredcards.map((card, index) => {
              return (
                <React.Fragment key={`${card.gid}-${index}`}>
                  {/*<div className={classes.wrapper}>*/}
                  <ImageContainer
                    key={`${card.gid}-${index}`}
                    card={card}
                    size={size}
                    height={1040 / 10}
                    width={744 / 10}
                    alt={card.name}
                    onIsVisible={() => {}}
                  />
                  {""}
                  {/*</div>*/}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Library;
