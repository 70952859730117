import React from "react";
import "./image.css";

const Image = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);
  return (
    <React.Fragment>
      <div style={{ position: "relative" }}>
        <img
          className="thumb"
          alt={props.alt}
          src={props.thumb}
          style={{
            visibility: isLoaded ? "hidden" : "visible",
            width: props.width,
            height: props.height,
          }}
        />
        <img
          onLoad={() => {
            setIsLoaded(true);
          }}
          onError={() => setError(true)}
          className="full image"
          style={{
            opacity: isLoaded ? 1 : 0,
            width: props.width,
            height: props.height,
          }}
          alt={props.alt}
          src={
            error ? "https://assets.orangeswim.dev/other/blank.png" : props.src
          }
        />
      </div>
    </React.Fragment>
  );
};

export default Image;
