import { rare } from "./dataMapper";

export enum NetPanelMode {
  normal,
  builder,
}

export enum CardType {
  Digitama = "Digitama",
  Digimon = "Digimon",
  Tamer = "Tamer",
  Option = "Option",
}

export enum DigimonStage {
  Baby = "Baby",
  InTraining = "In Training",
  Rookie = "Rookie",
  Champion = "Champion",
  Ultimate = "Ultimate",
  Mega = "Mega",
}

export enum DigimonAttribute {
  Data = "Data",
  Vaccine = "Vaccine",
  Virus = "Virus",
  Free = "Free",
  Variable = "Variable",
  Unknown = "Unknown",
}

export enum Rarity {
  Uncommon,
  Common,
  Rare,
  SuperRare,
  Promo,
}

export enum DColor {
  red = "Red",
  blue = "Blue",
  yellow = "Yellow",
  green = "Green",
  black = "Black",
  purple = "Purple",
  white = "White",
}

export interface DigimonData {
  id: string;
  name: string;
  ctype: CardType | string;
  color: DColor | string;
  lv?: number;
  cost?: number;
  ecost?: number;
  ecost2?: number;
  ecostcolor?: DColor | string;
  ecostcolor2?: DColor | string;
  dp?: number;
  dtype?: string;
  attr?: DigimonAttribute | string;
  stage?: DigimonStage | string;
  rare: Rarity | string;
  seffect?: string;
  meffect?: string;
  seceffect?: string;
  notes?: string;
  imageUrl?: string;
  language?: number;
  gid?: number;
  json?: string;
  baseid?: number;
  intl?: boolean;
}

interface CardSet {
  setName: string;
  quantity: number[];
  set: DigimonData[];
}

export var CardInfo: CardSet[] = [
  {
    setName: "Starter Deck Gaia Red",
    quantity: [4, 4, 4, 4, 4, 4, 4, 2, 4, 4, 2, 2],
    set: [
      {
        id: "ST1-01",
        language: 231,
        name: "Koromon",
        ctype: CardType.Digitama,
        color: DColor.red,
        lv: 2,
        dtype: "Lesser",
        stage: DigimonStage.InTraining,
        rare: Rarity.Common,
        seffect:
          "[During your turn] If this Digimon has 4 or more Evolution Sources, it gets +1000 DP.",
      },
      {
        id: "ST1-02",
        language: 231,
        name: "Biyomon",
        ctype: CardType.Digimon,
        color: DColor.red,
        lv: 3,
        cost: 2,
        ecost: 0,
        dp: 3000,
        dtype: "Chick",
        attr: DigimonAttribute.Vaccine,
        stage: DigimonStage.Rookie,
        rare: Rarity.Common,
      },
      {
        id: "ST1-03",
        language: 231,
        name: "Agumon",
        ctype: CardType.Digimon,
        color: DColor.red,
        lv: 3,
        cost: 3,
        ecost: 0,
        dp: 2000,
        dtype: "Reptile",
        attr: DigimonAttribute.Vaccine,
        stage: DigimonStage.Rookie,
        rare: Rarity.Common,
        seffect: "[During your turn] This Digimon gets +1000 DP.",
      },
      {
        id: "ST1-04",
        language: 231,
        name: "Dracomon",
        ctype: CardType.Digimon,
        color: DColor.red,
        lv: 3,
        cost: 3,
        ecost: 0,
        dp: 4000,
        dtype: "Dragon",
        attr: DigimonAttribute.Data,
        stage: DigimonStage.Rookie,
        rare: Rarity.Common,
      },
      {
        id: "ST1-05",
        language: 231,
        name: "Birdramon",
        ctype: CardType.Digimon,
        color: DColor.red,
        lv: 4,
        cost: 4,
        ecost: 2,
        dp: 5000,
        dtype: "Giant Bird",
        attr: DigimonAttribute.Vaccine,
        stage: DigimonStage.Champion,
        rare: Rarity.Common,
      },
      {
        id: "ST1-06",
        language: 231,
        name: "Coredramon",
        ctype: CardType.Digimon,
        color: DColor.red,
        lv: 4,
        cost: 5,
        ecost: 2,
        dp: 6000,
        dtype: "Dragon",
        attr: DigimonAttribute.Virus,
        stage: DigimonStage.Champion,
        rare: Rarity.Common,
        meffect:
          "<<Blocker>> (When your opponent's Digimon attacks, if this Digimon is Active, you may make it the target of that attack by Resting it.) [When it attacks] Memory -2.",
      },
      {
        id: "ST1-07",
        language: 231,
        name: "Greymon",
        ctype: CardType.Digimon,
        color: DColor.red,
        lv: 4,
        cost: 5,
        ecost: 2,
        dp: 4000,
        dtype: "Dinosaur",
        attr: DigimonAttribute.Vaccine,
        stage: DigimonStage.Champion,
        rare: Rarity.Uncommon,
        seffect:
          "<<Security Attack +1>> (When this Digimon attacks Security, it checks +1 more.)",
      },
      {
        id: "ST1-08",
        language: 231,
        name: "Garudamon",
        ctype: CardType.Digimon,
        color: DColor.red,
        lv: 5,
        cost: 6,
        ecost: 3,
        dp: 7000,
        dtype: "Bird Man",
        attr: DigimonAttribute.Vaccine,
        stage: DigimonStage.Ultimate,
        rare: Rarity.Uncommon,
        meffect:
          "[When Digivolved] 1 of your Digimon gets +3000 DP for this turn.",
      },
      {
        id: "ST1-09",
        language: 231,
        name: "MetalGreymon",
        ctype: CardType.Digimon,
        color: DColor.red,
        lv: 5,
        cost: 7,
        ecost: 3,
        dp: 7000,
        dtype: "Cyborg",
        attr: DigimonAttribute.Vaccine,
        stage: DigimonStage.Ultimate,
        rare: Rarity.Rare,
        seffect: "[During your turn] When this Digimon is blocked, +3 Memory.",
      },
      {
        id: "ST1-10",
        language: 231,
        name: "Phoenixmon",
        ctype: CardType.Digimon,
        color: DColor.red,
        lv: 6,
        cost: 10,
        ecost: 2,
        dp: 12000,
        dtype: "Holy beast",
        attr: DigimonAttribute.Vaccine,
        stage: DigimonStage.Mega,
        rare: Rarity.Rare,
        imageUrl: "https://assets.orangeswim.dev/images/mons/ST1-10_+.jpg",
      },
      {
        id: "ST1-11",
        language: 231,
        name: "WarGreymon",
        ctype: CardType.Digimon,
        color: DColor.red,
        lv: 6,
        cost: 12,
        ecost: 4,
        dp: 12000,
        dtype: "Dragon Man",
        attr: DigimonAttribute.Vaccine,
        stage: DigimonStage.Mega,
        rare: Rarity.SuperRare,
        meffect:
          "[During your turn] For every 2 of this Digimon's Evolution Sources, it gets <<Security Attack +1>>. (When this Digimon attacks Security, increase the number checked by 1.)",
      },
      {
        id: "ST1-12",
        language: 231,
        name: "Yagami Taichi",
        ctype: CardType.Tamer,
        color: DColor.red,
        cost: 2,
        rare: Rarity.Rare,
        meffect: "[During your turn] All of your Digimon get +1000 DP.",
        seceffect: "[Security] Play this card without paying the cost.",
      },
      {
        id: "ST1-13",
        language: 231,
        name: "Wing Blade",
        ctype: CardType.Option,
        color: DColor.red,
        cost: 1,
        rare: Rarity.Common,
        meffect: "[Main] 1 of your Digimon gets +3000 DP for this turn.",
        seceffect:
          "[Security] All of your Digimon get <Security Attack +1> until the end of your turn.",
      },
      {
        id: "ST1-14",
        language: 231,
        name: "Starlight Explosion",
        ctype: CardType.Option,
        color: DColor.red,
        cost: 2,
        rare: Rarity.Common,
        meffect:
          "[Main] All of your Security Digimon get +7000 DP until the end of your opponent's next turn.",
        seceffect:
          "[Security] All of your Security Digimon get +7000 DP for this turn.",
      },
      {
        id: "ST1-15",
        language: 231,
        name: "Giga Blaster",
        ctype: CardType.Option,
        color: DColor.red,
        cost: 6,
        rare: Rarity.Common,
        meffect:
          "[Main] Destroy up to 2 of your opponent's Digimon with 4000 DP or less.",
        seceffect: "[Security] Activate this card's [Main] effect.",
      },
      {
        id: "ST1-16",
        language: 231,
        name: "Terra Force",
        ctype: CardType.Option,
        color: DColor.red,
        cost: 8,
        rare: Rarity.Uncommon,
        meffect: "[Main] Destroy 1 of your opponent's Digimon.",
        seceffect: "[Security] Activate this card's [Main] effect.",
      },
    ],
  },
  {
    setName: "Starter Deck Gaia Blue",
    quantity: [4, 4, 4, 4, 4, 4, 4, 2, 4, 4, 2, 2],
    set: [
      {
        id: "ST2-01",
        language: 231,
        name: "Tsunomon",
        ctype: CardType.Digitama,
        color: DColor.blue,
        lv: 2,
        dtype: "Lesser",
        stage: DigimonStage.InTraining,
        rare: Rarity.Common,
        seffect:
          "[Your Turn] When this Digimon is battling an opponent’s Digimon with no Digivolution Sources, this Digimon gets +1000 DP.",
      },
      {
        id: "ST2-02",
        language: 231,
        name: "Gomamon",
        ctype: CardType.Digimon,
        color: DColor.blue,
        lv: 3,
        cost: 2,
        ecost: 0,
        dp: 3000,
        dtype: "Marine Animal",
        attr: DigimonAttribute.Vaccine,
        stage: DigimonStage.Rookie,
        rare: Rarity.Common,
      },
      {
        id: "ST2-03",
        language: 231,
        name: "Gabumon",
        ctype: CardType.Digimon,
        color: DColor.blue,
        lv: 3,
        cost: 3,
        ecost: 0,
        dp: 2000,
        dtype: "Reptile",
        attr: DigimonAttribute.Data,
        stage: DigimonStage.Rookie,
        rare: Rarity.Common,
        seffect:
          "[When Attacking] Discard 1 Digivolution Source from the bottom of 1 of your opponent's lv5 or lower Digimon.",
      },
      {
        id: "ST2-04",
        language: 231,
        name: "Bearmon",
        ctype: CardType.Digimon,
        color: DColor.blue,
        lv: 3,
        cost: 3,
        ecost: 0,
        dp: 4000,
        dtype: "Mammal",
        attr: DigimonAttribute.Vaccine,
        stage: DigimonStage.Rookie,
        rare: Rarity.Common,
      },
      {
        id: "ST2-05",
        language: 231,
        name: "Ikkakumon",
        ctype: CardType.Digimon,
        color: DColor.blue,
        lv: 4,
        cost: 4,
        ecost: 2,
        dp: 5000,
        dtype: "Marine Animal",
        attr: DigimonAttribute.Vaccine,
        stage: DigimonStage.Champion,
        rare: Rarity.Common,
      },
      {
        id: "ST2-06",
        language: 231,
        name: "Garurumon",
        ctype: CardType.Digimon,
        color: DColor.blue,
        lv: 4,
        cost: 5,
        ecost: 2,
        dp: 4000,
        dtype: "Beast",
        attr: DigimonAttribute.Vaccine,
        stage: DigimonStage.Champion,
        rare: Rarity.Uncommon,
        seffect:
          "[When Attacking] Discard a Digivolution Source from the bottom of 1 of your opponent’s Digimon.",
      },
      {
        id: "ST2-07",
        language: 231,
        name: "Grizzlymon",
        ctype: CardType.Digimon,
        color: DColor.blue,
        lv: 4,
        cost: 5,
        ecost: 2,
        dp: 6000,
        dtype: "Beast",
        attr: DigimonAttribute.Vaccine,
        stage: DigimonStage.Champion,
        rare: Rarity.Uncommon,
        meffect:
          "<Blocker> (When your opponent attacks, rest this Digimon to change the attack’s target to this Digimon). [When Attacking] Get -2 Memory.",
      },
      {
        id: "ST2-08",
        language: 231,
        name: "WereGarurumon",
        ctype: CardType.Digimon,
        color: DColor.blue,
        lv: 5,
        cost: 7,
        ecost: 3,
        dp: 7000,
        dtype: "Beast Man",
        attr: DigimonAttribute.Data,
        stage: DigimonStage.Ultimate,
        rare: Rarity.Rare,
        seffect:
          "[Your Turn] When your opponent has a Digimon with no Digivolution Sources, <Security Attack +1> (When this Digimon attacks the Security, it reveals 1 extra card).",
      },
      {
        id: "ST2-09",
        language: 231,
        name: "Zudomon",
        ctype: CardType.Digimon,
        color: DColor.blue,
        lv: 5,
        cost: 6,
        ecost: 3,
        dp: 7000,
        dtype: "Marine Animal",
        attr: DigimonAttribute.Vaccine,
        stage: DigimonStage.Ultimate,
        rare: Rarity.Uncommon,
        meffect:
          "[When Digivolved] Discard 2 Digivolution Sources from the bottom of 1 of your opponent’s Digimon.",
      },
      {
        id: "ST2-10",
        language: 231,
        name: "Plesiomon",
        ctype: CardType.Digimon,
        color: DColor.blue,
        lv: 6,
        cost: 10,
        ecost: 2,
        dp: 12000,
        dtype: "Dragon",
        attr: DigimonAttribute.Data,
        stage: DigimonStage.Mega,
        rare: Rarity.Rare,
      },
      {
        id: "ST2-11",
        language: 231,
        name: "MetalGarurumon",
        ctype: CardType.Digimon,
        color: DColor.blue,
        lv: 6,
        cost: 12,
        ecost: 4,
        dp: 11000,
        dtype: "Dragon Man",
        attr: DigimonAttribute.Vaccine,
        stage: DigimonStage.Mega,
        rare: Rarity.SuperRare,
        meffect:
          "[When Attacking] (Once Per Turn) This Digimon becomes Active.",
      },
      {
        id: "ST2-12",
        language: 231,
        name: "Matt Ishida",
        ctype: CardType.Tamer,
        color: DColor.blue,
        cost: 2,
        rare: Rarity.Uncommon,
        meffect:
          "[Start of Your Turn] If an opponent’s Digimon has no Digivolution Sources, get +1 Memory.",
        seceffect: "[Security] Play this card without paying the cost.",
      },
      {
        id: "ST2-13",
        language: 231,
        name: "Vulcan's hammer",
        ctype: CardType.Option,
        color: DColor.blue,
        cost: 0,
        rare: Rarity.Common,
        meffect: "[Main] Get +1 Memory.",
        seceffect: "[Security] Get +2 Memory.",
      },
      {
        id: "ST2-14",
        language: 231,
        name: "Sorrow Blue",
        ctype: CardType.Option,
        color: DColor.blue,
        cost: 2,
        rare: Rarity.Common,
        meffect:
          "[Main] Choose 1 of your opponent’s Digimon with no Digivolution Sources. That Digimon cannot attack or block until the end of their next turn.",
        seceffect:
          "[Security] Choose 1 of your opponent’s Digimon with no Digivolution Sources. That Digimon cannot attack or block until the end of your next turn.",
      },
      {
        id: "ST2-15",
        language: 231,
        name: "Wolf Claw",
        ctype: CardType.Option,
        color: DColor.blue,
        cost: 4,
        rare: Rarity.Common,
        meffect:
          "[Main] Choose 1 of your Digimon’s Digivolution Sources, and play it as a separate Digimon without paying its cost.",
        seceffect: "[Security] Activate this card’s [Main] effect.",
      },
      {
        id: "ST2-16",
        language: 231,
        name: "Ice Wolf Claw",
        ctype: CardType.Option,
        color: DColor.blue,
        cost: 7,
        rare: Rarity.Uncommon,
        meffect:
          "[Main] Return 1 of your opponent’s Digimon back into their hand. That Digimon’s Digivolution Sources are discarded.",
        seceffect: "[Security] Activate this card’s [Main] effect.",
      },
    ],
  },
];

const allCards: DigimonData[] = [];

CardInfo.forEach((set) =>
  allCards.push(
    ...set.set.map((card) => {
      var rareNum: Rarity = Number(card.rare);
      card.rare = rare[rareNum];
      return { ...card, json: JSON.stringify(card).toLocaleLowerCase() };
    })
  )
);

export default allCards;
