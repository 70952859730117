import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React, { useState } from "react";

import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export const CardsToggle = (props) => {
  //const {official, setOfficial, proxy, setProxy} = props;
  const { formats, setFormats } = props;

  const handleFormat = (event, newFormats) => {
    if (newFormats.length) {
      setFormats(newFormats);
    }
  };

  return (
    <ToggleButtonGroup
      value={formats}
      onChange={handleFormat}
      aria-label="device"
      size="small"
    >
      <ToggleButton value="official" aria-label="official">
        <Tooltip title="Show Official English Cards">
          <Typography component="div">
            {formats.includes("official") ? (
              <Box fontWeight="fontWeightBold">EN</Box>
            ) : (
              "EN"
            )}
          </Typography>
        </Tooltip>
      </ToggleButton>

      <ToggleButton value="unofficial" aria-label="unofficial">
        <Tooltip title="Show Fan Translated Cards">
          <Typography component="div">
            {formats.includes("unofficial") ? (
              <Box fontWeight="fontWeightBold">PROXY</Box>
            ) : (
              "PROXY"
            )}
          </Typography>
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default CardsToggle;
