import React, { useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
//import GridListTileBar from "@material-ui/core/GridListTileBar";
//import IconButton from "@material-ui/core/IconButton";
//import StarBorderIcon from "@material-ui/icons/StarBorder";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Button from "@material-ui/core/Button";
//import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
//import tileData from './tileData';

import { Link } from "react-router-dom";
//import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import ometa from "../ometa.png";
//import EventTile from "./eventTile";
import cardsStore from "../store/cards/cards";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      //backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: "100%",
      //height: 450,
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
    },
    titleBar: {
      background:
        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    icon: {
      color: "white",
    },
    imageContainer: {
      //width: "200px" /* width of container */,
      //height: "200px" /* height of container */,
      overflow: "hidden",
      //border: "5px solid black",
      borderTopLeftRadius: "25px",
      background: "lightgrey",
      width: "65%",
    },
    imageContainer2: {
      //width: "200px" /* width of container */,
      //height: "200px" /* height of container */,
      overflow: "hidden",
      //border: "5px solid black",
      borderTopLeftRadius: "25px",
      background: "lightgrey",
      width: "50%",
      position: "absolute",
      right: "0px",
      top: "0px",
    },
    image: {
      margin: "-20% 0 0 -20%",
      width: "max( 140% , 250px)",
      filter: "opacity(0.1)",
      background: "white",
    },
    image2: {
      margin: "-20% 0 0 -20%",
      width: "max( 140% , 200px)",
      filter: "opacity(0.5)",
      background: "white",
    },
    button: {
      width: "100%",
      background: "darkorange",
      borderRadius: "4px 4px 0px 0px",
      fontWeight: "bold",
      "&:after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "0%",
        height: "100%",
        backgroundColor: "rgba(255,255,255,0.4)",
        transition: "none",
      },
      "&:hover": {
        background: "darkorange",
      },
    },
    tileText: {
      position: "absolute",
      bottom: 0,
      width: "100%",
    },
    eventName: {
      color: theme.palette.grey[900],
      fontWeight: "bolder",
      marginLeft: "10px",
      lineHeight: "unset",
      textShadow:
        "1px 1px 5px #fff, -1px 1px 3px #aaa, 1px 1px 3px #aaa,-1px -1px 3px #aaa, 1px -1px 3px #aaa",
    },
    eventDate: {
      color: theme.palette.grey[700],
      fontWeight: "bolder",
      marginLeft: "10px",
      lineHeight: "unset",
    },
  })
);

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *     featured: true,
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
const tileDataInitial = [...Array(30).keys()].map((val, ind) => ({
  id: ind,
  title: `data${ind}`,
  coverindex: 40 + ind,
}));

const AdvancedGridList = (props) => {
  const classes = useStyles();
  const cards = cardsStore.useCards();

  const [tileData, setTileData] = React.useState(tileDataInitial);
  // Will hold a ref to a "bottom" element we will observe
  const [bottom, setBottom] = React.useState(null);
  // Will hold the IntersectionOberver
  const bottomObserver = React.useRef(null);

  const [loaded, setLoaded] = React.useState(5);

  const getGridListCols = () => {
    if (isWidthUp("lg", props.width)) {
      return 4;
    }

    if (isWidthUp("md", props.width)) {
      return 3;
    }

    if (isWidthUp("sm", props.width)) {
      return 2;
    }

    return 1;
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setTileData((data) => {
          if (entry.isIntersecting) {
            console.log("bottom");
            var newData = [...data, ...data];
            console.log(newData);
            return newData;
          } else {
            return data;
          }
        });
      },
      { threshold: 0.25, rootMargin: "50px" }
    );
    bottomObserver.current = observer;
  }, []);

  React.useEffect(() => {
    const observer = bottomObserver.current;
    if (bottom) {
      observer.observe(bottom);
    }
    return () => {
      if (bottom) {
        observer.unobserve(bottom);
      }
    };
  }, [bottom]);

  const onload = () => {
    setLoaded((val) => val + 1);
  };

  return (
    <div className={classes.root}>
      <GridList
        cellHeight={150}
        spacing={20}
        className={classes.gridList}
        cols={getGridListCols()}
      >
        {cards.basecards.length > 0 &&
          tileData.map((tile, index) => {
            const imageUrl =
              index < loaded
                ? cards.basecards[tile.coverindex].imageUrl
                : ometa;

            return (
              <GridListTile key={`${tile.img}_${index}`} cols={1} rows={1}>
                <div className={classes.imageContainer}>
                  <img
                    onLoad={onload}
                    src={imageUrl}
                    alt={tile.title}
                    className={classes.image}
                  />
                </div>
                <div className={classes.imageContainer2}>
                  <img
                    src={imageUrl}
                    alt={tile.title}
                    className={classes.image2}
                  />
                </div>
                <div className={classes.tileText}>
                  <Typography variant="h6" className={classes.eventName}>
                    {index % 2 ? "BigByte 1" : "DigiRumble 5 Season 2 Olympics"}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.eventDate}>
                    06 Oct 2020
                  </Typography>
                  <Button
                    component={Link}
                    to={`/events/${tile.id}`}
                    className={`${classes.button} shinebutton`}
                  >
                    View Event
                  </Button>
                </div>
              </GridListTile>
            );
          })}

        <div ref={setBottom} className={classes.botom}>
          Loading
        </div>
      </GridList>
    </div>
  );
};

export default withWidth()(AdvancedGridList);
