//@ts-nocheck
import { DigimonData } from "./cardinfo";

export function FilterFunction(
  baseCardsAll: DigimonData[],
  filter: string[],
  sort: (boolean | string)[],
  //@ts-ignore
  setFilteredCards,
  language: number[],
  alt: boolean = true,
  formats: string[]
) {
  var newcards: DigimonData[] = [];

  var official = formats.includes("official");
  var proxy = formats.includes("unofficial");

  // remove or include alt arts
  const baseCards = baseCardsAll.filter((card) => {
    if (card.intl === true && official == false) {
      return false;
    }
    if (card.intl === false && proxy == false) {
      return false;
    }

    if (alt == false && card.notes) {
      return (
        card.notes.includes("parallel") == false &&
        //@ts-ignore
        language.includes(card.language)
      );
    }
    //@ts-ignore
    return language.includes(card.language);
  });

  if (filter.length == 0) {
    //setFilteredCards(baseCards);
    //return;
    newcards = [];
    //newcards = [...baseCards];
  } else {
    const lowfilter = filter.map((word: string) => word.toLocaleLowerCase());
    const numberPattern = /^@([a-zA-Z0-9]+)@(gte|lte|eq)@([0-9]+)/i;
    const numberRegExp = new RegExp(numberPattern);
    const filterGroup = /(^"[A-Za-z0-9]+":).+/i;
    const filterGroupRegExp = new RegExp(filterGroup);

    if (sort[3] == "auto") {
      newcards = [...baseCards];
      //sort filters
      //filter.sort();
      var smartFilter = {};
      for (var i = 0; i < filter.length; i++) {
        if (filterGroupRegExp.test(filter[i])) {
          //@ts-ignore
          const [_, filterKey] = filterGroupRegExp.exec(filter[i]);
          //@ts-ignore
          if (smartFilter["_x_" + filterKey] == undefined) {
            smartFilter["_x_" + filterKey] = [lowfilter[i]];
          } else {
            smartFilter["_x_" + filterKey] = [
              ...smartFilter["_x_" + filterKey],
              lowfilter[i],
            ];
          }
        } else {
          smartFilter["_y_" + filter[i]] = [lowfilter[i]];
        }
      }
      for (let key in smartFilter) {
        var val = smartFilter[key];
        newcards = newcards.filter((card) => {
          for (var k = 0; k < val.length; k++) {
            if (val[k][0] == "@") {
              //use number function
              if (numberRegExp.test(val[k]) == false) {
                return false;
              }
              const [_, property, operator, number] = numberRegExp.exec(val[k]);
              switch (operator) {
                case "gte":
                  return card[property] >= Number(number);
                case "lte":
                  return card[property] <= Number(number);
                case "eq":
                  return card[property] == Number(number);
                default:
                  return false;
              }
            }
            if (card.json.indexOf(val[k]) !== -1) {
              return true;
            }
          }
          return false;
        });
      }
    } else if (sort[3] == "and") {
      //AND filters
      newcards = [...baseCards];
      for (var i = 0; i < filter.length; i++) {
        newcards = newcards.filter((card) => {
          if (filter[i][0] == "@") {
            //use number function
            if (numberRegExp.test(filter[i]) == false) {
              return false;
            }
            const [_, property, operator, number] = numberRegExp.exec(
              filter[i]
            );
            switch (operator) {
              case "gte":
                return card[property] >= Number(number);
              case "lte":
                return card[property] <= Number(number);
              case "eq":
                return card[property] == Number(number);
              default:
                return false;
            }
          }
          if (card.json.indexOf(lowfilter[i]) !== -1) {
            return true;
          }
        });
      }
    } else if (sort[3] == "or") {
      // OR filters
      newcards.push(
        ...baseCards.filter((card) => {
          for (var i = 0; i < filter.length; i++) {
            if (filter[i][0] == "@") {
              //use number function
              const [_, property, operator, number] = numberRegExp.exec(
                filter[i]
              );
              switch (operator) {
                case "gte":
                  return card[property] >= Number(number);
                case "lte":
                  return card[property] <= Number(number);
                default:
                  return false;
              }
            }
            if (card.json.indexOf(lowfilter[i]) !== -1) {
              return true;
            }
          }
          return false;
        })
      );
    }
  }

  //sorting code
  //sort using id
  var sortFuncA;
  var sortFuncB;
  switch (sort[1]) {
    case "id":
    case "color":
    case "artist":
      sortFuncB = function (a: DigimonData, b: DigimonData) {
        const valA = a[sort[1] as string] || "";
        const valB = b[sort[1] as string] || "";
        return valA.localeCompare(valB);
      };
      break;
    default:
      sortFuncB = function (a: DigimonData, b: DigimonData) {
        const valA = a[sort[1] as string] || 0;
        const valB = b[sort[1] as string] || 0;
        return valA - valB;
      };
  }
  switch (sort[0]) {
    case "id":
    case "color":
    case "artist":
      sortFuncA = function (a: DigimonData, b: DigimonData) {
        const valA = a[sort[0] as string] || "";
        const valB = b[sort[0] as string] || "";
        const val = valA.localeCompare(valB);
        return val == 0 ? sortFuncB(a, b) : val;
      };
      break;
    default:
      sortFuncA = function (a: DigimonData, b: DigimonData) {
        const valA = a[sort[0] as string] || 0;
        const valB = b[sort[0] as string] || 0;
        const val = valA - valB;
        return val == 0 ? sortFuncB(a, b) : val;
      };
  }
  var sortFunc =
    (sort[2] as boolean) == true
      ? sortFuncA
      : (a, b) => {
          return sortFuncA(b, a);
        };
  newcards.sort(sortFunc);

  setFilteredCards(newcards);
}
