import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import AssignmentIcon from "@material-ui/icons/Assignment";
import StarsIcon from "@material-ui/icons/Stars";
import InfoIcon from "@material-ui/icons/Info";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { Link } from "react-router-dom";

export const mainListItems = (classes) => {
  //const {classes} = props;
  return (
    <div>
      <ListItem component={Link} to="/" button>
        <ListItemIcon className={classes.listIcon}>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItem button disabled>
        <ListItemIcon className={classes.listIcon}>
          <StarsIcon />
        </ListItemIcon>
        <ListItemText primary="MetaDecks" />
      </ListItem>
      <ListItem component={Link} to="/events" button>
        <ListItemIcon className={classes.listIcon}>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Events" />
      </ListItem>
      <ListItem component={Link} to="/mydecks" button>
        <ListItemIcon className={classes.listIcon}>
          <AccountBoxIcon />
        </ListItemIcon>
        <ListItemText primary="My Decks" />
      </ListItem>
      <ListItem
        component="a"
        target="_blank"
        rel="nooppener"
        href="https://digimoncard.dev"
        button
      >
        <ListItemIcon className={classes.listIcon}>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Card Library" />
      </ListItem>
      <ListItem
        component="a"
        target="_blank"
        rel="nooppener"
        href="https://digimoncard.dev/deckbuilder"
        button
      >
        <ListItemIcon className={classes.listIcon}>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Deck Builder" />
      </ListItem>
      <ListItem button>
        <ListItemIcon className={classes.listIcon}>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary="About" />
      </ListItem>
    </div>
  );
};

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
