import React, { Fragment } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { DColor, CardType } from "../cardinfo";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      //'pointer-events': 'none',
      "user-select": "none",
      border: "solid 1px black",
      backgroundColor: (props) => props.bcolor,
      color: (props) => props.fcolor,
      fontWeight: 500,
      height: "max(3vmin, 30px)",
      width: "100%",
      alignItems: "center",
      //margin: "0.5vmin",
      borderTopLeftRadius: "max(3vmin,15px)",
      borderBottomLeftRadius: "max(3vmin,15px)",
      overflow: "hidden",
      "& div": {
        alignItems: "center",
      },
    },
    level: {
      color: "white",
      backgroundColor: "black",
      padding: "5px max(0.5vmin,1.66px)  0 max(0.5vmin,1.66px) ",
      height: "100%",
      fontWeight: "bold",
      flex: "1 0 auto",
    },
    triangle: {
      width: 0,
      height: 0,
      borderRight: "1vmin solid transparent",
      borderBottom: "max(3vmin, 30px) solid black",
    },
    triangle2: {
      width: 0,
      height: 0,
      borderLeft: "1vmin solid transparent",
      borderTop: "max(3vmin, 30px) solid black",
    },
    text: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: "max(1.6vmin, 12pt)",
      flex: "50 1 auto",
      fontWeight: "bold",
    },
    small: {
      fontSize: "max(1.4vmin, 10pt)",
      letterSpacing: "-0.1vmin",
    },
    cardid: {
      fontSize: "1.4vmin",
      flex: "1 0 auto",
      color: "white",
      backgroundColor: "black",
      height: "100%",
      padding: "0.2vmin",
    },
    cost: {
      background: "rgb(23,68,101)",
      // @ts-ignore
      // eslint-disable-next-line
      background: "radial-gradient(circle, #456984 21%, rgba(22,43,77,1) 100%)",
      width: "max(3vmin,40px)",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      color: "white",
      //flex: "1 0 auto",
    },
    nocost: {
      background: "black",
      width: "3vmin",
      height: "100%",
      flex: "1 0 auto",
    },
    smallSpacer: {
      width: "0.5vmin",
    },
    ecost: {
      // @ts-ignore
      width: "max(3vmin,30px)",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      //flex: "1 0 auto",
      backgroundColor: (props) => props.ebcolor,
      color: (props) => props.efcolor,
    },
    ecost2: {
      // @ts-ignore
      width: "max(3vmin,30px)",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      //flex: "1 0 auto",
      backgroundColor: (props) => props.ebcolor2,
      color: (props) => props.efcolor2,
    },
    egg: {
      width: "max(6vmin,70px)",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& div": {
        borderRadius: "50%/60% 60% 40% 40%",
        backgroundColor: "white",
        width: "max(1.4vmin, 14px)",
        height: "max(1.8vmin, 18px)",
      },
    },
  })
);

const findColors = (color) => {
  var bcolor;
  var fcolor;
  switch (color) {
    case DColor.blue:
      bcolor = "#0998d3";
      fcolor = "white";
      break;
    case DColor.yellow:
      bcolor = "#f9dc04";
      fcolor = "black";
      break;
    case DColor.green:
      bcolor = "#01965d";
      fcolor = "white";
      break;
    case DColor.white:
      bcolor = "white";
      fcolor = "black";
      break;
    case DColor.black:
      bcolor = "#5a5a5a";
      fcolor = "white";
      break;
    case DColor.purple:
      bcolor = "#5664a3";
      fcolor = "white";
      break;
    case DColor.red:
    default:
      bcolor = "#e61030";
      fcolor = "white";
  }
  return [bcolor, fcolor];
};

export function SmallCard(props) {
  const { card } = props;
  const [bcolor, fcolor] = findColors(card.color);
  const [ebcolor, efcolor] = findColors(card.ecostcolor);
  const [ebcolor2, efcolor2] = findColors(card.ecostcolor2);

  const classes = useStyles({
    bcolor: bcolor,
    fcolor: fcolor,
    ebcolor: ebcolor,
    efcolor: efcolor,
    ebcolor2: ebcolor2,
    efcolor2: efcolor2,
  });

  return (
    <div className={classes.root}>
      {card.ctype === CardType.Digitama ? (
        <Fragment>
          <div className={classes.egg}>
            <div />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className={card.cost != null ? classes.cost : classes.nocost}>
            {card.cost}
          </div>
          <div className={card.ecost != null ? classes.ecost : classes.nocost}>
            {card.ecost}
          </div>
          {card.ecostcolor2 != null && (
            <div
              className={card.ecost2 != null ? classes.ecost2 : classes.nocost}
            >
              {card.ecost2}
            </div>
          )}
        </Fragment>
      )}

      {card.lv > 0 ? (
        <Fragment>
          <div className={classes.level}>
            <span className={classes.small}>Lv </span>
            {card.lv}
          </div>
          <div className={classes.triangle} />
        </Fragment>
      ) : (
        <Fragment>
          <div className={classes.triangle} />
          <div className={classes.smallSpacer} />
        </Fragment>
      )}

      <div className={classes.text}>{card.name}</div>
      <div className={classes.triangle2} />
      <div className={classes.cardid}>{card.id}</div>
    </div>
  );
}

export default SmallCard;
