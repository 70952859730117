import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { mainListItems } from "./listItems";

import ometa from "../ometa.png";
import Events from "./events";
import EventDetail from "./eventDetail";
import Home from "./home";
import MyDecks from "./mydecks";
import SignIn from "./signIn";
import Library from "./library";
import { UserContext } from "../connections/userProvider";
import { ContextMenuComponent } from "./contextMenu";
//import Chart from './Chart';
//import Deposits from './Deposits';
//import Orders from './Orders';
import { Route, Switch, Link } from "react-router-dom";
import Terrier from "../terrier.png";
import YouTubeIcon from "@material-ui/icons/YouTube";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import TwitchIcon from "./TwitchIcon";
import Button from "@material-ui/core/Button";

function Copyright() {
  return (
    <>
      <Box>
        <IconButton
          href="https://www.youtube.com/channel/UCjEZcbpUHQAShBruDv9RIWg"
          target="_blank"
          rel="nooppener"
        >
          <YouTubeIcon />
        </IconButton>
        <IconButton
          href="https://twitter.com/OrangeswimDev"
          target="_blank"
          rel="nooppener"
        >
          <TwitterIcon />
        </IconButton>
        <IconButton
          href="https://twitch.tv/orangeswim"
          target="_blank"
          rel="nooppener"
        >
          <TwitchIcon />
        </IconButton>
        <IconButton
          href="https://facebook.com/digimoncard.dev"
          target="_blank"
          rel="nooppener"
        >
          <FacebookIcon />
        </IconButton>
      </Box>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link style={{ color: "orange" }} href="https://digimoncard.dev/" to="">
          Meta.Digimoncard.dev
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography variant="caption" color="textSecondary" align="center">
        Akiyoshi Hongo, Bandai, and Toei Animation respectively own Digimon
        images, copyrights and trademarks. I am not affiliated with Bandai.
      </Typography>
    </>
  );
}

const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    //marginRight: 36,
    width: theme.spacing(7),
    filter: "opacity(1)",
    transition: theme.transitions.create(["width", "filter"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButtonHidden: {
    width: 0,
    filter: "opacity(0)",
    transition: theme.transitions.create(["width", "filter"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    maxWidth: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
    [theme.breakpoints.down("xs")]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    height: "calc(100% - 64px)",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  logo: {
    height: "35px",
  },
  listIcon: {
    minWidth: "40px",
  },
  supportBox: {
    padding: theme.spacing(1),
    whiteSpace: "normal",
  },
  link: {
    textDecoration: "none",
  },
  terrier: {
    width: "40px",
  },
  button: {
    //margin: "0.5vmin",
    background: "#eee",
    fontWeight: "bold",
    "& *": {
      textDecoration: "none !important",
    },
    clipPath: `polygon(
      0.5vmin 0.5vmin,                
      1.5vmin 0%,                 
      calc(100% - 1.5vmin) 0%,    
      calc(100% - 0.5vmin) 0.5vmin,               
      100% calc(100% - 0.5vmin),  
      calc(100% - 0.5vmin) 100%, 
      0.5vmin 100%,               
      0 calc(100% - 0.5vmin)     
    )`,
    "&:nth-child(2)": {
      clipPath: `polygon(
        0vmin 0.5vmin,                
      0.5vmin 0%,                 
      calc(100% - 0.5vmin) 0%,    
      calc(100% - 0vmin) 0.5vmin,               
      calc(100% - 0.5vmin) calc(100% - 0.5vmin),  
      calc(100% - 1.5vmin) 100%, 
      1.5vmin 100%,               
      0.5vmin calc(100% - 0.5vmin)      
      )`,
    },
  },
  button2: {
    //margin: "0.5vmin",
    background: "#eee",
    fontWeight: "500",
    "& *": {
      textDecoration: "none !important",
    },
    clipPath: `polygon(
      0vmin 0.5vmin,                
    0.5vmin 0%,                 
    calc(100% - 0.5vmin) 0%,    
    calc(100% - 0vmin) 0.5vmin,               
    calc(100% - 0.5vmin) calc(100% - 0.5vmin),  
    calc(100% - 1.5vmin) 100%, 
    1.5vmin 100%,               
    0.5vmin calc(100% - 0.5vmin)      
    )`,
  },
}));

const useStyles2 = makeStyles((theme) => ({
  support: {
    transition: theme.transitions.create("margin-left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: (props) => (props.open ? "0px" : "100px"),
    width: drawerWidth * 0.9,
  },
}));

export default function Dashboard(props) {
  const classes = useStyles();
  const { user, signOut, signedIn } = useContext(UserContext);
  const { open, setOpen } = props;
  const classes2 = useStyles2({ open });
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar disableGutters className={classes.toolbar}>
          <IconButton
            //edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <img
            alt="DigimonCard.dev logo"
            className={classes.logo}
            src="https://assets.orangeswim.dev/other/logo_sm.png"
          />
          {
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            ></Typography>
          }
          {signedIn ? (
            <Button
              variant="contained"
              className={classes.button2}
              href="/myDecks"
              rel="nooppener"
            >
              {user.doc.displayName}
            </Button>
          ) : (
            <Button
              variant="contained"
              className={classes.button2}
              href="/signin"
              rel="nooppener"
            >
              Sign In
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img alt="omni meta logo" src={ometa} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems(classes)}</List>
        <Divider />
        <div className={classes2.support}>
          <Typography variant="body2" className={classes.supportBox}>
            <b>
              Donate{" "}
              <a
                href="https://www.paypal.me/orangeswim"
                className={classes.link}
              >
                PayPal
              </a>
            </b>
            <br />
            Support the site and buy me boba
            <br />{" "}
            <img className={classes.terrier} alt="terriermon" src={Terrier} />
          </Typography>
        </div>
        <Divider />
        {/*<List>{secondaryListItems}</List>*/}
      </Drawer>
      <main className={classes.content}>
        <ContextMenuComponent />
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            <Route
              path="/events"
              exact
              render={(props) => <Events classes={classes} />}
            />
            <Route
              path="/events/:id"
              exact
              render={(props) => <EventDetail />}
            />
            <Route
              path={["/mydecks/:id", "/mydecks"]}
              render={(props) => <MyDecks />}
            />
            <Route path={["/library"]} render={(props) => <Library />} />
            <Route path="/signin" render={() => <SignIn classes={classes} />} />
            <Route
              path="/"
              exact
              render={(props) => <Home classes={classes} />}
            />
          </Switch>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
