import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";

//<!-- The core Firebase JS SDK is always required and must be listed first -->
//<script src="https://www.gstatic.com/firebasejs/7.14.2/firebase-app.js"></script>

//<!-- TODO: Add SDKs for Firebase products that you want to use
//firebase.google.com/docs/web/setup#available-libraries -->
//<script src="https://www.gstatic.com/firebasejs/7.14.2/firebase-analytics.js"></script>

// Your web app's Firebase configuration
https: var firebaseConfig = {
  apiKey: "AIzaSyDEaUwBgBspo4p20d6Tu6vL4ipNtNwNHA4",
  authDomain: "digimoncarddev.firebaseapp.com",
  databaseURL: "https://digimoncarddev.firebaseio.com",
  projectId: "digimoncarddev",
  storageBucket: "digimoncarddev.appspot.com",
  messagingSenderId: "421941714852",
  appId: "1:421941714852:web:5de7116f514577cdbd2b31",
  measurementId: "G-D7X7WZVLXR",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};

export const signOut = () => {
  auth.signOut();
};

/*
  export const generateUserDocument1 = async (user, additionalData={}) => {
    if (!user) return;
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
      const { email, displayName, photoURL } = user;
      try {
        await userRef.set({
          displayName,
          email,
          photoURL,
          ...additionalData
        });
      } catch (error) {
        console.error("Error creating user document", error);
      }
    }
    return getUserDocument(user.uid);
  };

  const getUserDocument = async uid => {
    if (!uid) return null;
    try {
      const userDocument = await firestore.doc(`users/${uid}`).get();
      return {
        uid,
        ...userDocument.data()
      };
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };

  */
