import React, { useState } from "react";
//import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
//import Metagrid from './metagrid';

import { useParams, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
//import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import Typography from "@material-ui/core/Typography";
import HeaderImage from "../header.jpg";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";

import SmallCard from "./smallcard";
import cardsStore from "../store/cards/cards";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  cardPreview: {
    borderRadius: "30px",
  },
  header: {
    background: "grey",
    padding: theme.spacing(0),
    position: "relative",
    minHeight: "200px",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    "&:before": {
      content: "''",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundImage: `url(${HeaderImage})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      filter: `brightness(0.3) blur(1px)`,
    },
  },
  headerInside: {
    position: "relative",
    textAlign: "center",
  },
  title: { fontWeight: "bolder" },
  sub: {
    fontWeight: "bold",
  },
  place: {
    //textAlign: "right",
    fontWeight: "600",
  },
  qty: {
    fontWeight: "800",
    color: "black",
    fontSize: "1.2rem",
  },
  preview: {
    padding: "10px",
    alignContent: "start",
    background: `linear-gradient(-90deg, rgba(0,0,0,.05) 1px, transparent 1px),
    linear-gradient(rgba(0,0,0,.05) 1px, transparent 1px), 
    linear-gradient(-90deg, rgba(0, 0, 0, .04) 1px, transparent 1px),
    linear-gradient(rgba(0,0,0,.04) 1px, transparent 1px),
    linear-gradient(transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
    linear-gradient(-90deg, #aaa 1px, transparent 1px),
    linear-gradient(-90deg, transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
    linear-gradient(#aaa 1px, transparent 1px),
    #f2f2f2`,
    backgroundSize: `4px 4px,
    4px 4px,
    80px 80px,
    80px 80px,
    80px 80px,
    80px 80px,
    80px 80px,
    80px 80px`,
  },
  popover: {
    pointerEvents: "none",
  },
  card: {
    maxHeight: "calc(100vh - 50px)",
  },
}));

function placeString(val) {
  switch (val) {
    case 0:
      return "NONE";
    case 1:
      return "1st";
    case 2:
      return "2nd";
    case 3:
      return "3rd";
    case 4:
      return "4th";
    case 5:
      return "5-8th";
    case 6:
      return "8-16th";
    case 7:
      return "15-32nd";
    default:
      return " ";
  }
}

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const tileData = [...Array(30).keys()].map((val, ind) => ({
  id: ind,
  title: `data${ind}`,
  coverindex: 40 + ind,
}));

const EventDetailHeader = (props) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.header}>
        <Grid container className={classes.headerInside}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h2" className={classes.title}>
              BigByte 6
            </Typography>
          </Grid>
          <Hidden mdDown>
            <Grid item lg={12}>
              DATE | TableTopSimulator | Online
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Grid item lg={4} sm={6} xs={12}>
              <Typography className={classes.sub}>10 Dec 2020</Typography>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Typography className={classes.sub}>TableTopSimulator</Typography>
            </Grid>
            <Grid item lg={4} sm={12} xs={12}>
              <Typography className={classes.sub}>Online</Typography>
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </>
  );
};

function OnError(event) {
  //event.persist();
  //console.log(event.target.attributes['src']);
  event.target.src = "https://assets.orangeswim.dev/other/blank.png";
}

export function findCardByCardId(id, basecards) {
  var proxy = null;
  for (var i = 0; i < basecards.length; i++) {
    let card = basecards[i];
    if (card.language === 231 && card.id.localeCompare(id) === 0) {
      if (card.intl) {
        return card;
      }
      proxy = card;
    }
  }
  return proxy;
}

var EventDetail = (props) => {
  const classes = useStyles();
  const cards = cardsStore.useCards();
  let { id } = useParams();
  //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const info = tileData[id];

  const data = [
    {
      place: 1,
      name: "Tai",
      deck: ["BT1-001", "BT1-002", "BT1-050", "BT1-084"],
      id: 1,
      archtype: 1,
    },
    {
      place: 2,
      name: "Joe",
      deck: ["BT1-002", "BT1-002", "BT3-019", "BT3-040", "BT3-111", "BT3-090"],
      id: 1,
      archtype: 1,
    },
    { place: 3, name: "Tai", deck: ["BT1-003", "BT1-002"], id: 1, archtype: 1 },
    { place: 4, name: "Tai", deck: ["BT1-004", "BT1-002"], id: 1, archtype: 1 },
    { place: 5, name: "Tai", deck: ["BT1-005", "BT1-002"], id: 1, archtype: 1 },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [cardPreview, setCardPreview] = useState(null);
  const [previewIndex, setPreviewIndex] = useState(0);

  const handlePopoverOpen = (card) => {
    return (event) => {
      setAnchorEl(event.currentTarget);
      setCardPreview(card);
    };
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChangePreview = (index) => {
    return () => {
      setPreviewIndex(index);
    };
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <EventDetailHeader info={info} />
      <Grid container>
        <Hidden smDown>
          <Grid item md={6} container className={classes.preview}>
            {cards.basecards.length > 0 &&
              data[previewIndex].deck.map((val, index) => {
                const card = findCardByCardId(val, cards.basecards);
                return (
                  <>
                    <Grid container>
                      <Grid item xs={1}>
                        <span className={classes.qty}>x4</span>
                      </Grid>
                      <Grid
                        item
                        xs={11}
                        onMouseEnter={handlePopoverOpen(card)}
                        onMouseLeave={handlePopoverClose}
                      >
                        <SmallCard card={card} />
                      </Grid>
                    </Grid>
                  </>
                );
              })}

            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.cardPreview,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {cardPreview && (
                <img
                  src={cardPreview.imageUrl}
                  className={classes.card}
                  onError={OnError}
                  alt="preview"
                />
              )}
            </Popover>
          </Grid>
        </Hidden>
        <Grid container item spacing={3} md={6} sm={12}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <List component="nav" aria-label="main mailbox folders">
                {data.map((val, index) => (
                  <>
                    <ListItem key={index} button>
                      <Grid container>
                        <Grid item xs={2} className={classes.place}>
                          {placeString(val.place)}
                        </Grid>
                        <Grid item xs={4}>
                          {val.name}
                        </Grid>
                        <Grid item xs={6}>
                          <Button onClick={handleChangePreview(index)}>
                            Preview
                          </Button>
                          <Button
                            component={Link}
                            to={`/events/${id}/decks/${val.id}`}
                            className={`${classes.button} shinebutton`}
                          >
                            Deck Details
                          </Button>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </>
                ))}
                <ListItem button>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Inbox" />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <DraftsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Drafts" />
                </ListItem>
              </List>
              <Divider />
              <List component="nav" aria-label="secondary mailbox folders">
                <ListItem button>
                  <ListItemText primary="Trash" />
                </ListItem>
                <ListItemLink href="#simple-list">
                  <ListItemText primary="Spam" />
                </ListItemLink>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>xs=12 sm=6</Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>xs=12 sm=6</Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>xs=6 sm=3</Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>xs=6 sm=3</Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>xs=6 sm=3</Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>xs=6 sm=3</Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EventDetail;
