import React from "react";
//import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
//import Paper from "@material-ui/core/Paper";
//import Metagrid from './metagrid';
import AdvancedGridList from './advancedgridlist';
var Events = (props) => {
  //const {classes} = props;
  //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <>
      <Grid container spacing={3}>
      <AdvancedGridList />
      </Grid>
    </>
  );
};

export default Events;
