import { BehaviorSubject } from "rxjs";
import allCards from "../../cardinfo";
import { dataMapper } from "../../dataMapper";
import { useState, useEffect } from "react";
const initialState = {
  status: "",
  basecards: [],
  loadedcards: [],
  error: "",
  loading: false,
  language: [231],
  formats: ["official"],
  alt: true,
  filteredcards: [],
  filter: ["BT1"],
  sort: ["id", "id", true, "auto"],
  size: 25,
  menuState: {
    mouseX: null,
    mouseY: null,
    card: null,
  },
  panelMode: 0, //normal , 1 is builder open
  workingDeck: [],
};
const subject = new BehaviorSubject(initialState);

let state = initialState;

const cardsStore = {
  init: () => {
    //state = { ...state, loading: true };
    //subject.next(state);
  },
  load: () => {
    //load data
    const url = "https://digimoncard.dev/data.php";
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        var basecards = data.map(dataMapper);
        state = {
          ...state,
          loading: false,
          basecards: [...allCards, ...basecards],
        };
        subject.next(state);
      })
      .catch(function (error) {
        console.log("Request failed", error);
      });
  },
  subscribe: (setState) => subject.subscribe(setState),
  setBasecards: (data) => {
    state = {
      ...state,
      basecards: data,
    };
    subject.next(state);
  },
  setFilteredcards: (data) => {
    state = {
      ...state,
      filteredcards: data,
    };
    subject.next(state);
  },
  setFilter: (data) => {
    state = {
      ...state,
      filter: data,
    };
    subject.next(state);
  },
  setSize: (data) => {
    state = {
      ...state,
      size: data,
    };
    subject.next(state);
  },
  setFormats: (data) => {
    state = {
      ...state,
      formats: data,
    };
    subject.next(state);
  },
  setSort: (data) => {
    state = {
      ...state,
      sort: data,
    };
    subject.next(state);
  },
  setAlt: (data) => {
    state = {
      ...state,
      alt: data,
    };
    subject.next(state);
  },
  setMenuState: (data) => {
    state = {
      ...state,
      menuState: data,
    };
    subject.next(state);
  },
  setWorkingDeck: (data) => {
    state = {
      ...state,
      workingDeck: data,
    };
    subject.next(state);
  },
  setState: (data) => {
    state = {
      ...state,
      ...data,
    };
    subject.next(state);
  },
  initialState,
  useCards: () => {
    const [cards, setCards] = useState(initialState);
    useEffect(() => {
      subject.subscribe(setCards);
    }, []);
    return cards;
  },
};

export default cardsStore;
