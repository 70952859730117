import React, { Component, createContext } from "react";
import { auth, signOut } from "./firebase";
import { generateUserDocument } from "./phpdb";
export const UserContext = createContext({
  user: null,
  signOut: null,
  signedIn: false,
  setNewUserFlag: (name, callback) => {},
});

class UserProvider extends Component {
  state = {
    user: null,
    newUserFlag: false,
    displayName: "",
    callback: null,
  };

  componentDidMount = () => {
    window.user = this;
    auth.onAuthStateChanged(async (userAuth) => {
      var user;
      if (this.state.newUserFlag) {
        user = await generateUserDocument(userAuth, {
          displayName: this.state.displayName,
        });
      } else {
        user = await generateUserDocument(userAuth);
      }

      if (userAuth) {
        userAuth.doc = user;
      }

      this.setState({ user: userAuth, newUserFlag: false });
      //console.log(userAuth);
      // @ts-ignore
      if (user) {
        console.log(`Signed in as ${userAuth.email}`);
        console.log(userAuth, user);
      }
    });
  };

  signOut = () => {
    signOut();
    this.setState({ user: null });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.newUserFlag === false && this.state.newUserFlag) {
      this.state.callback();
    }
  }

  setNewUserFlag = (displayName, callback) => {
    this.setState({
      newUserFlag: true,
      displayName: displayName,
      callback: callback,
    });
  };

  render = () => {
    return (
      <UserContext.Provider
        value={{
          user: this.state.user,
          signOut: this.signOut,
          signedIn: this.state.user != null,
          setNewUserFlag: this.setNewUserFlag,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  };
}
export default UserProvider;
