import React from "react";
import Image from "./image";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import "./image-container.css";
import { ContextMenuClick } from "./contextMenu";
import cardsStore from "../store/cards/cards";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      //width: (props) => `${props.size}vmin`,
      //height: (props) => `calc(${props.size}vmin * 1.396)`,

      display: "flex",
      border: (props) => `${props.size / 15}px solid black`,
      "border-radius": (props) => `${props.size / 15}vmin`,
      background: "black",
      flexGrow: 0,
      overflow: "hidden",
    },
    imageContainer: {
      flex: "1",
      width: (props) => `${props.size}vmin`,
      height: (props) => `calc(${props.size}vmin * 1.396)`,
      //"z-index": "-2",

      //transition: "0.25s",
      //paddingBottom: (props) => `${props.aspectRatio}%`,
      overflow: "hidden",
    },
    outer: {
      margin: (props) => `${props.size / 50}vmin`,
      minWidth: (props) => `${props.size}vmin`,
      minHeight: (props) => `calc(${props.size}vmin * 1.396)`,
    },

    outline: {
      //position: "relative",
      background: "transparent",
      "&::before": {
        content: "attr(title)",
        position: "absolute",
        "-webkit-text-stroke": "0.3em black",
        left: 0,
        "z-index": -1,
      },
    },
  })
);
const thumb =
  "https://assets.orangeswim.dev/other/2020_card_backstage_design.jpg";

const ImageContainer = (props) => {
  const aspectRatio = (props.height / props.width) * 100;
  const classes = useStyles({ size: props.size, aspectRatio });
  const ref = React.useRef();
  const [isVisible, setIsVisible] = React.useState(false);

  const { setMenuState, setWorkingDeck } = cardsStore;

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        if (!isVisible) {
          props.onIsVisible();
          setIsVisible(true);
        }
        observerElement.unobserve(ref.current);
      }
    },
  });

  const url = props.card.imageUrl
    ? props.card.imageUrl
    : `https://assets.orangeswim.dev/images/mons/${[props.card.id]}.jpg`;

  const { panelMode, workingDeck } = cardsStore.useCards();

  const lclick = ContextMenuClick(
    { panelMode, workingDeck },
    setMenuState,
    setWorkingDeck,
    props.card,
    false
  );

  const rclick = ContextMenuClick(
    { panelMode, workingDeck },
    setMenuState,
    setWorkingDeck,
    props.card
  );

  return (
    <div className={classes.outer}>
      <a
        href={props.url}
        ref={ref}
        rel="noopener noreferrer"
        target="_BLANK"
        //className="image-container"
        //style={
        //  {
        //paddingBottom: `${aspectRatio}%`,
        //width: props.width,
        //height: props.height,
        //  }
        // }
        className={classes.link}
      >
        {isVisible && (
          <div
            className={classes.imageContainer}
            onClick={lclick}
            onContextMenu={rclick}
          >
            <Image
              src={url}
              thumb={thumb}
              alt={props.alt}
              width={`${props.size}vmin`}
              height={`calc(${props.size}vmin * 1.396)`}
            />
          </div>
        )}
      </a>
    </div>
  );
};

export default ImageContainer;
