//import { Deck } from "./localdb";
//@ts-ignore
import * as md5 from "md5";

/*
interface Deck {
  cards: string[]; //cardid
  name: string; //deck name
  description: string;
  cloud: number;
  uid?: string;
  data: string[]; //to json array, always ordered by cardid
  baseId?: number[]; //don't save this
  private: boolean;
  config: { version: number; order: number[]; lastModified: string }; //to json data
}
*/
function getRandomInt(min, max) {
  const min_ = Math.ceil(min);
  const max_ = Math.floor(max);
  return Math.floor(Math.random() * (max_ - min_ + 1)) + min_;
}

function getRandomName() {
  const num = getRandomInt(0, 2);
  switch (num) {
    case 0:
      return "Vaccine";
    case 1:
      return "Virus";
    default:
      return "Data";
  }
}

function getRandomNum() {
  return `_${Math.floor(Math.random() * 8000000).toString(
    36
  )}${new Date().valueOf().toString(36).slice(5)}`;
}

export const getDecks = async (user, callback) => {
  var formData = new FormData();
  formData.append("m", "6");
  formData.append("id", user.uid);
  let response = await fetch("https://digimoncard.dev/data.php", {
    method: "POST",
    body: formData,
  });
  var data = await response.json();
  console.log(data);
};

export const getOCTGN = async (callback) => {
  var formData = new FormData();
  formData.append("m", "8");
  let response = await fetch("https://digimoncard.dev/data.php", {
    method: "POST",
    body: formData,
  });
  var data = await response.json();
  callback(data);
};

/*
export enum DColor {
  red = "Red",
  blue = "Blue",
  yellow = "Yellow",
  green = "Green",
  black = "Black",
  purple = "Purple",
  white = "White",
}*/

const color = [
  "",
  "Red",
  "",
  "Blue",
  "Yellow",
  "Black",
  "Green",
  "Purple",
  "White",
  "",
];

const colorToCode = color.reduce((prev, curr, curri) => {
  //@ts-ignore
  prev[curr] = curri;

  return prev;
}, {});

//publishDeck( user, description, pubData, mainColor, colors );
export const publishDeck = async (
  user,
  description,
  pubData,
  mainColor,
  colors,
  callback,
  isTourney
) => {
  if (!user) return;
  const doc = user.doc;
  const data = JSON.stringify(pubData);
  const deck = JSON.stringify(pubData.deck);
  const hash = md5.default(deck);

  console.log("Publishing data");
  var formData = new FormData();
  formData.append("m", isTourney ? "10" : "12");
  formData.append("id", user.uid);
  formData.append("mainColor", colorToCode[mainColor]);
  formData.append("description", description);
  formData.append("hash", hash);
  formData.append("data", data);
  formData.append(
    "colors",
    Object.keys(colors)
      .map((color) => `${colorToCode[color]}`)
      .join("")
  );
  let response = await fetch("https://digimoncard.dev/data.php", {
    method: "POST",
    body: formData,
  });
  let resp = await response.text();
  callback && callback(resp);
};

export const getDeckFromHash = async (user, pubDeck, callback) => {
  if (!user) return;

  const deck = JSON.stringify(pubDeck);
  const hash = md5.default(deck);
  var formData = new FormData();
  formData.append("m", "15");
  formData.append("id", user.uid);
  formData.append("hash", hash);
  let response = await fetch("https://digimoncard.dev/data.php", {
    method: "POST",
    body: formData,
  });
  let resp = await response.text();
  callback && callback(JSON.parse(resp));
};

export const getDecklist = async (tournament, callback) => {
  var formData = new FormData();
  formData.append("m", tournament ? "9" : "13");
  let response = await fetch("https://digimoncard.dev/data.php", {
    method: "POST",
    body: formData,
  });
  let resp = await response.json();
  callback && callback(resp);
};

export const getPublicDeck = async (pubKey, callback) => {
  var formData = new FormData();
  formData.append("m", "14");
  formData.append("pubKey", pubKey);
  let response = await fetch("https://digimoncard.dev/data.php", {
    method: "POST",
    body: formData,
  });
  let resp = await response.json();
  callback && callback(resp);
};

export const updateUserDocument = async (user, decks) => {
  if (!user) return;
  const doc = user.doc;
  doc.bin1 = JSON.stringify(decks);
  console.log("Uploading data");
  //get userdata
  var formData = new FormData();
  formData.append("m", "7");
  formData.append("id", user.uid);
  formData.append("displayName", doc.displayName);
  formData.append("email", user.email);
  formData.append("favCard", doc.favCard);
  formData.append("bin1", doc.bin1);
  formData.append("decklist", doc.decklist);
  let response = await fetch("https://digimoncard.dev/data.php", {
    method: "POST",
    body: formData,
  });
};

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;

  //get userdata
  var formData = new FormData();
  formData.append("m", "1");
  formData.append("id", user.uid);
  let response = await fetch("https://digimoncard.dev/data.php", {
    method: "POST",
    body: formData,
  });
  var data = await response.json();
  if (data.length !== 1) {
    //user does not exist
    // create new user in db
    const displayName = additionalData
      ? additionalData.displayName || `${getRandomName()}${getRandomNum()}`
      : `${getRandomName()}${getRandomNum()}`;
    formData = new FormData();
    formData.append("m", "3");
    formData.append("id", user.uid);
    formData.append("displayName", displayName);
    formData.append("email", user.email);
    response = await fetch("https://digimoncard.dev/data.php", {
      method: "POST",
      body: formData,
    });
    let data1 = await response.json();
    //check for errors
    return data1[0];
  } else {
    return data[0];
  }
};
