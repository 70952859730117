import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import InputAdornment from "@material-ui/core/InputAdornment";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import Button from "@material-ui/core/Button";
import GoogleSignIn from "../assets/btn_google_signin_light_normal_web@2x.png";
import { signInWithGoogle, auth } from "../connections/firebase";
import { UserContext } from "../connections/userProvider";
import { useHistory } from "react-router-dom";

import clsx from "clsx";
import Grid from "@material-ui/core/Grid";

import Metagrid from "./metagrid";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    error: {
      padding: "1vmin",
      margin: "1vmin",
      background: "#f007",
    },
    pageRoot: {
      padding: "2vmin",
      "& form": {
        flexDirection: "column",
        alignItems: "center",

        display: "flex",
      },
    },
    formItem: {
      margin: "1vmin",
    },
    gSignIn: {
      width: "200px",
      height: "58px",
      padding: "1vmin",
      "& img": {
        height: "48px",
      },
    },
    or: {
      margin: "1vmin",
      marginBottom: 0,
      //display: "inline",
      fontWeight: 600,
    },
  })
);

export const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const { user, signOut, signedIn } = useContext(UserContext);

  const history = useHistory();
  console.log(user, signedIn);

  useEffect(() => {
    if (signedIn) {
      history.push("/myDecks");
    }
  }, [signedIn]);

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    auth.signInWithEmailAndPassword(email, password).catch((error) => {
      setError("Error signing in with password and email!");
      console.error("Error signing in with password and email", error);
    });
  };

  const onChangeHandler = (event) => {
    const { id, value } = event.currentTarget;
    if (id === "userEmail") {
      setEmail(value.trim());
    } else if (id === "userPassword") {
      setPassword(value);
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1 className="text-3xl mb-2 text-center font-bold">Sign In</h1>
      <Paper className={classes.pageRoot}>
        {error !== null && <Paper className={classes.error}>{error}</Paper>}
        <form>
          <TextField
            className={classes.formItem}
            id="userEmail"
            label="E-mail"
            placeholder="E-mail"
            fullWidth
            type="email"
            autoComplete="email"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineIcon />
                </InputAdornment>
              ),
            }}
            onChange={(event) => onChangeHandler(event)}
          />
          <TextField
            className={classes.formItem}
            id="userPassword"
            label="Password"
            type="password"
            autoComplete="current-password"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyIcon />
                </InputAdornment>
              ),
            }}
            onChange={(event) => onChangeHandler(event)}
          />
          <Button
            onClick={(event) => {
              signInWithEmailAndPasswordHandler(event, email, password);
            }}
            variant="contained"
            color="primary"
            size={"large"}
          >
            Sign In
          </Button>
          <div className={classes.or}>or</div>
          <Button
            onClick={signInWithGoogle}
            className={classes.gSignIn}
            size={"large"}
          >
            <img src={GoogleSignIn} />
          </Button>

          <p className="text-center my-3">
            Don't have an account?{" "}
            <Link to="/signup" className="text-blue-500 hover:text-blue-600">
              Sign up here
            </Link>{" "}
            <br />{" "}
            <Link
              to="/passwordReset"
              className="text-blue-500 hover:text-blue-600"
            >
              Forgot Password?
            </Link>
          </p>
        </form>
      </Paper>
    </div>
  );
};

var Home = (props) => {
  const { classes } = props;
  console.log(classes);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <>
      <SignIn />
    </>
  );
};

export default Home;

//export default SignIn;
